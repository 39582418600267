import React from 'react'
import { css } from '../../../../styled-system/css'

import Logo from '../../../images/QuentinPro.svg'
import { Link } from 'gatsby'
import scrollTo from 'gatsby-plugin-smoothscroll'

const Header = () => {
  return (
    <div
      className={css({
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: '#0E111C',
        padding: '1rem 0',
        width: '100%',
        lgDown: {
          paddingTop: '4rem',
          paddingBottom: '6rem',
        },
      })}
    >
      <div
        className={css({
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: '5rem',
          width: '80%',
        })}
      >
        <div
          className={css({
            display: 'flex',
            flexDirection: 'column',
            flex: '1',
            gap: '5rem',
            lgDown: {
              gap: '2.5rem',
              alignItems: 'center',
            },
          })}
        >
          <h1
            className={css({
              textAlign: 'left',
              color: '#FFF',
              fontSize: '2.5rem',
              fontFamily: 'Assistant !',
              fontWeight: '600',
              lgDown: {
                fontSize: '2rem',
              },
            })}
          >
            Votre fiche de poste,{' '}
            <span
              className={css({
                color: '#37BBEF',
                lgDown: {
                  display: 'block',
                  textAlign: 'center',
                },
              })}
            >
              mon expertise
            </span>
          </h1>
          <p
            className={css({
              fontFamily: 'Assistant !',
              fontWeight: '600',
              fontSize: '1.25rem',
              color: '#7983A7',
              lgDown: {
                fontSize: '1rem',
                textAlign: 'center',
              },
            })}
          >
            Fort de mes 15 ans d&apos;expertise dans l&apos;IT, mon réseau me
            permet de recruter les profils les plus adéquats à votre demande.
          </p>
          {process.env.GATSBY_NO_PROFILES === 'true' ? (
            <div>
              <button
                className={css({
                  color: 'white',
                  background: '#006BFF',
                  padding: '1rem 2rem',
                  fontFamily: 'Assistant !',
                  fontWeight: '600',
                  fontSize: '1.25rem',
                  cursor: 'pointer',
                  lgDown: {
                    padding: '.75rem',
                    fontSize: '.9rem',
                  },
                })}
                onClick={() => scrollTo('#accompagnement')}
              >
                Découvrir l&apos;accompagnement
              </button>
            </div>
          ) : (
            <Link to={'/recrutement/profils'}>
              <button
                className={css({
                  color: 'white',
                  background: '#006BFF',
                  padding: '1rem 2rem',
                  fontFamily: 'Assistant !',
                  fontWeight: '600',
                  fontSize: '1.25rem',
                  cursor: 'pointer',
                  lgDown: {
                    padding: '.75rem',
                    fontSize: '.9rem',
                  },
                })}
              >
                Découvrir les profils
              </button>
            </Link>
          )}
        </div>
        <div
          className={css({
            flex: '1',
            position: 'relative',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            lgDown: {
              display: 'none',
            },
          })}
        >
          <img src={Logo} alt="Quentin Pro" />
        </div>
      </div>
    </div>
  )
}

export default Header
